<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      visibilityState: document.visibilityState,
      ownerDetails: {}
    };
  },
  mounted() {
    console.log('visibilityState:', this.visibilityState);

    if (localStorage.getItem('token')) {
      this.checkToken();
    } else {
      this.$router.push('/');
    }

    // Listen for the beforeunload event
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    // Clean up event listener
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    checkToken() {
      axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/precontract/checkToken', {
     // axios.post('http://localhost:3000/api/precontract/checkToken', {
        token: localStorage.getItem('token')
      })
      .then((response) => {
        if (response.status === 200) {
          this.ownerDetails = response.data;
          console.log(this.ownerDetails);
        } else {
          this.$router.push('/');
        }
      })
      .catch((error) => {
        console.error('Error checking token:', error);
        this.$router.push('/');
      });
    },
    handleBeforeUnload(event) {
      // Show an alert when the page is about to be unloaded
      // This may not work reliably in all browsers
      // event.preventDefault();
      // event.returnValue = '';

      // Prepare tracking data
      const trackingData = {
        name: "CLOSE or REFRESH",
        logedInUserID: this.ownerDetails._id,
        action: "CLOSED or REFRESHED",
        description: `${this.ownerDetails.name} closed or refreshed the page`
      };

      // Send tracking data using Axios
      axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/tracking/addTracking', trackingData)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error('Error sending tracking data:', error);
        });
    }
  }
};
</script>

<style>
body {
background-color: black !important;
}
*{
  font-family: 'Oswald' !important;
}
</style>
