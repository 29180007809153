const mongoose = require('mongoose')

const preContractOwnerSchema = new mongoose.Schema({
    name: {
        type: String,
        required: true,
        min: 3,
        max: 255
    },
    email:{
        type: String,
        required: true,
        min: 6,
        max: 255
    },
    phone:{
        type: String,
        required: true,
        min: 6,
        max: 255
    },
    password:{
        type: String,
        required: true,
        min: 6,
        max: 1024
    },
    isSigned: { 
        type: Boolean,
        default: false,
        required: true
    },
    signingDate:{
        type: String,   
    },
    logoImageURL:{
        type: String,
    },
    startingWeek:{
        type: String,
    },
    totalWeeksNeeded:{
        type: String,
    },
    BauantragErstellen:[{
       name: String,
       startWeek: Number,
       duration: Number,
       isChecked: Boolean,
       disabled: Boolean,
       pdfLink: String
    }],
    WHGFlache:[{
            name: String,
            startWeek: Number,
            duration: Number,
            isChecked: Boolean,
            disabled: Boolean,
            pdfLink: String
    }],
    Abwassermanagement:[{
            name: String,
            startWeek: Number,
            duration: Number,
            isChecked: Boolean,
            disabled: Boolean,
            pdfLink: String
    }],
    Installationsvoraussetzung:[{
            name: String,
            startWeek: Number,
            duration: Number,
            isChecked: Boolean,
            disabled: Boolean,
            pdfLink: String
    }],
    ANGEBOTLink:{
        type: String,
    },
    FRAGEBOGENLink:{
        type: String,
    },
    DATENBLATTLink:{
        type: String,
    },
    CHECKLISTELink:{
        type: String,
    },
    VERTRAGLink:{
        type: String,
    },
    ANLAGENLink:{
        type: String,
    },
    nextcloudFolderLink:{
        type: String,
    },
    sessioToken:{
        type: String,
    },


})

module.exports = mongoose.model('PreContractOwner', preContractOwnerSchema)
    



