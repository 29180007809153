<template>
    <div >
        <div id="pdfPage">
        <div id="firstThree">
            <div id="firstOne">
                <h3 style="margin-bottom:15px;">
                    ANGEBOT:
                </h3>
                <img style="cursor:pointer;" src="https://i.ibb.co/ccg7Jx8/IMG-5768-qf9gunss08awge3oxc6qut2q9ye0udyydowdnkcbr6.png" alt="logo" @click.prevent="gotoAngebot">
                <h3  style="margin-top:1%; color:black" >HIER DOWNLOADEN</h3>
            </div>
            <div id="firstOne">
                <h3 style="margin-bottom:15px;">
                    FRAGEBOGEN:
                </h3>
                <img style="cursor:pointer;" src="https://i.ibb.co/mJZvng2/IMG-5767-qf9gunss08awge3oxc6qut2q9ye0udyydowdnkcbrg.png" alt="logo" @click.prevent="gotoFragebogen">
                <h3  style="margin-top:1%;color:black">HIER DOWNLOADEN</h3>
            </div>
            <div id="firstOne" >
                <h3 style="margin-bottom:15px;">
                    VERTRAG:
                </h3>
                <img style="cursor:pointer;" src="https://i.ibb.co/ccg7Jx8/IMG-5768-qf9gunss08awge3oxc6qut2q9ye0udyydowdnkcbr6.png" alt="logo" @click.prevent="gotoVertag">
                <h3  style="margin-top:1%;color:black">HIER DOWNLOADEN</h3>
            </div>
           
        </div>
        <div id="secondThree">
            <div id="secondOne">
                <h3 style="margin-bottom:15px;">
                    DATENBLATT:
                </h3>
                <img style="cursor:pointer;" src="https://i.ibb.co/ccg7Jx8/IMG-5768-qf9gunss08awge3oxc6qut2q9ye0udyydowdnkcbr6.png" alt="logo"  @click.prevent="gotoDatenBlatt">
                <h3 style="margin-top:1%;color:black">HIER DOWNLOADEN</h3>
            </div>
            <div id="secondOne">
                <h3 style="margin-bottom:15px;">
                    KUNDEN-CHECKLISTE:
                </h3>
                <img style="cursor:pointer;" src="https://i.ibb.co/mJZvng2/IMG-5767-qf9gunss08awge3oxc6qut2q9ye0udyydowdnkcbrg.png" alt="logo" @click.prevent="gotoChecklist">
                <h3  style="margin-top:1%;color:black">HIER DOWNLOADEN</h3>
            </div>
           
            <div id="secondOne">
                <h3 style="margin-bottom:15px;">
                    ANLAGEN:
                </h3>
                <img style="cursor:pointer;" src="https://i.ibb.co/mJZvng2/IMG-5767-qf9gunss08awge3oxc6qut2q9ye0udyydowdnkcbrg.png" alt="logo" @click.prevent="gotoAnlagen">
                <h3  style="margin-top:1%;color:black">HIER DOWNLOADEN</h3>
            </div>
            </div>
        </div>
          <br/>
         <br/>
    </div>
  
</template>
<script>
import axios from 'axios';
export default {
    name: 'PdfComponent',
    data() {
        return {
            isPopupOpen: false,
            ownerDetails:{}
        }
    },
    methods: {
        gotoAngebot() {
            window.open(this.ownerDetails.ANGEBOTLink, '_blank');
            this.addTracking('Angebot', 'Opened Angebot')
        },
        gotoFragebogen() {
            window.open(this.ownerDetails.FRAGEBOGENLink, '_blank');
            this.addTracking('Fragebogen', 'Opened Fragebogen')
        },
        gotoDatenBlatt() {
            window.open(this.ownerDetails.DATENBLATTLink, '_blank');
            this.addTracking('DatenBlatt', 'Opened DatenBlatt')
        },
        gotoChecklist() {
            window.open(this.ownerDetails.CHECKLISTELink, '_blank');
            this.addTracking('Checklist', 'Opened Checklist')
        },
        gotoVertag() {
            window.open(this.ownerDetails.VERTRAGLink, '_blank');
            this.addTracking('Vertag', 'Opened Vertag')
        },
        gotoAnlagen() {
            window.open(this.ownerDetails.ANLAGENLink, '_blank');
            this.addTracking('Anlagen', 'Opened Anlagen')
        },
        addTracking(name, action){
      console.log(name,this.ownerDetails)
      axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/tracking/addTracking', {
        name: name,
        logedInUserID: this.ownerDetails._id,
        action: action,
        description: this.ownerDetails.name + ' '+ action + ' the '+ name
      }).then((response) => {
        console.log(response)
      }).catch((error) => {
        console.log(error)
      })
    },
    },
    mounted(){
        if(localStorage.getItem('token')) {
            axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/precontract/checkToken', {
               // axios.post('http://localhost:3000/api/precontract/checkToken', {
                token: localStorage.getItem('token')
            }).then((response) => {
                if(response.status === 200) {
                    this.ownerDetails = response.data
                    console.log(this.ownerDetails)
                }
                else {
                    this.$router.push('/')
                }
            })
            .catch((error) => {
                this.$router.push('/')
            })
        }
        else {
            this.$router.push('/')
        }
    }
}
</script>
<style scoped>
    #pdfPage {
        margin-top: 5%;
        margin-left:auto;
        margin-right:auto;
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    #firstThree {
        color:white;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    #secondThree {
        margin-top: 5%;
        color:white;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    img:hover {
        transform: scale(1.1);
        transition: 0.5s;
    }
    @media(max-width: 768px) {
        #pdfPage {
            width: 100%;
        }
        #firstThree {
            flex-direction: column;
        }
        #secondThree {
            flex-direction: column;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        #pdfPage {
            width: 100%;
            font-size: medium;
        }
       
    }
</style>