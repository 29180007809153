<template>
  <div>
    <div>
      <div
        class="popup center"
        v-bind:class="{ active: isBauantragsformularActive }"
      >
        <h3>Information</h3>
        <p id="info">
           Der Link zu den Formularen ist der Name auf der linken Seite. Hier findest du auch eine Anleitung, wie du Informationen für das Formular schnell und einfach bekommst.
          Falls noch Fragen offen sind, rufe uns gerne an: 05244 9066943.
        </p>
        <button class="close" @click="closePopup">X</button>
      </div>
      <div
        class="popup center"
        v-bind:class="{ active: isFinished }"
      >
        <h3>Information</h3>
        <p id="info">
          Vielen Dank, dass Sie uns Bescheid gesagt haben, wir werden uns so schnell wie möglich bei Ihnen melden!
        </p>
        <button class="close" @click="closePopup">X</button>
      </div>
      <div
        class="popup center"
        v-bind:class="{ active: isFinishedtask }"
        style="width: 20%"
      >
        <div style="padding:20%;">
        <h3>{{infoTaskName}}</h3>
        <p id="info">
          Einen Schritt näher dran!
        </p>
        <button class="close" @click="closePopup">X</button>
      </div>
      </div>
      <div
        class="popup center"
        v-bind:class="{ uploadPopup: opentUploadpopup }"
      >
      <div class=" upload-container">
        <h3 v-if="!hasFile">Hochladen von {{ taskNameforUpload }}</h3>
        <h3 v-else style="color:#97bf0d;">Hochladen von {{ selectedFile.name }}</h3>
        <label for="file" class="file-label" v-if="!hasFile">
          <input type="file" id="file" ref="file" @change="handleFileUpload">
        <span >Wählen Sie eine Datei</span>
      </label>
      <div v-else>
        <div class="loading-container" v-if="!showUpdatesUpload">
          <div class="loading-spinner"></div>
          <h4 id="uploading">Hochladen {{ selectedFile.name }}</h4>
        </div>
        <div v-else>
          <h4>{{ updatesUpload }}</h4>
        </div>
      </div>
        <button class="close" @click="closeUploadPopup">X</button>
      </div>
    </div>
    </div>
    <div v-bind:class="{ disabled: isPopupOpen }">
      <table class="gantt-table" @mouseover="addTracking('Gantt-chart', 'mouseover' )">
        <thead>
          <tr style="height: 40px;">
            <th style="width: 15%;">Fertig?</th>
            <th
              v-for="(week, index) in weeks"
              :key="week"
              :class="{ 'current-week': index === currentWeekIndex }"
              style="width: 50px;  position: relative; "
            >
              <span
                v-if="showCallNotification(week)"
                id="callNotification"
                style=""
                ><img
                  src="https://i.ibb.co/WHWwbRX/Picture-21.png"
                  alt="Screenshot-2023-12-01-at-13-58-49"
                  border="0"
                  style="width:50%"
              /></span>
              <p id="week" :class="{ borderGo: index == weeks.length - 1 }">
                {{ week }}
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="width: 25%;">
              <p id="taskHeading">Bauantrag erstellen:</p>
            </td>
          </tr>
          <tr
            v-for="(task, index) in BauantragErstellen"
            :key="index"
            style="height: 50px;"
          >
            <td
              style="width: 250px; display: flex; align-items: center;  margin-top: 1px;"
            >
              <input
                id="checkboxInput"
                type="checkbox"
                v-model="task.isChecked"
                @change="onCheckboxChange(task)"
                :disabled="task.disabled"
                v-if="!task.isChecked"
              />
              <div
                v-else-if="task.isChecked"
                :class="{ disableClick: task.disabled }"
                class="checkbox-mark"
                @click="undoTask(task)"
              >
                X
              </div>
              <span
                v-html="task.name"
                style="vertical-align: top; width:90%; font-weight:bold; text-decoration: underline;"
                id="taskName"
                @click.prevent="uploadDocument(task)"

              ></span>
              <img
                src="https://i.ibb.co/cDGJBVS/Picture-1.png"
                alt="Picture-1"
                border="0"
                style="width:5%; margin-left:15%; vertical-align: middle; cursor: pointer;"
                @click="openPopup(task)"
              /> 
            </td>
            <td
              v-for="(week, weekIndex) in weeks"
              :key="weekIndex"
              style="width: 150px;"
            >
              <div
                v-if="isTaskInWeek(task, weekIndex)"
                v-bind:class="{ 'ganttWhiteBackGround':isTaskInWeek(task, weekIndex) , 'ganttGreenBackGround':isTaskInWeek(task, weekIndex) && task.isChecked }"
                class="gantt-bar"
                :style="getBarStyle(task, weekIndex)"
              ></div>
            </td>
          </tr>
          <tr>
            <td style="width: 25%;">
              <p id="taskHeading">WHG Fläche:</p>
            </td>
          </tr>
          <tr
            v-for="(task, index) in WHGFlache"
            :key="index"
            style="height: 50px;"
          >
            <td
              style="width: 250px; display: flex; align-items: center; margin-top: 1px;"
            >
              <input
                id="checkboxInput"
                type="checkbox"
                v-model="task.isChecked"
                @change="onCheckboxChange(task)"
                :disabled="task.disabled"
                v-if="!task.isChecked"
              />
              <div
                v-else-if="task.isChecked"
                :class="{ disableClick: task.disabled }"
                class="checkbox-mark"
                @click="undoTask(task)"
              >
                X
              </div>
              <span
                v-html="task.name"
                style="vertical-align: top;  width: 90%; font-weight:bold; text-decoration: underline; cursor: pointer;"
                id="taskName"
                @click.prevent="uploadDocument(task)"
              ></span>
              <img
                src="https://i.ibb.co/cDGJBVS/Picture-1.png"
                alt="Picture-1"
                border="0"
                style="width:5%; margin-left:10%; cursor: pointer;"
                @click="openPopup(task)"
              />
            </td>
            <td
              v-for="(week, weekIndex) in weeks"
              :key="weekIndex"
              style="width: 150px;"
            >
              <div
                v-if="isTaskInWeek(task, weekIndex)"
                v-bind:class="{ 'ganttWhiteBackGround':isTaskInWeek(task, weekIndex) , 'ganttGreenBackGround':isTaskInWeek(task, weekIndex) && task.isChecked }"
                class="gantt-bar"
                :style="getBarStyle(task, weekIndex)"
                style=""
              ></div>
            </td>
          </tr>
          <tr>
            <td style="width: 25%;">
              <p id="taskHeading">Abwassermanagement:</p>
            </td>
          </tr>
          <tr
            v-for="(task, index) in Abwassermanagement"
            :key="index"
            style="height: 50px;"
          >
            <td
              style="width: 250px; display: flex; align-items: center; margin-top: 1px;"
            >
              <input
                id="checkboxInput"
                type="checkbox"
                v-model="task.isChecked"
                @change="onCheckboxChange(task)"
                :disabled="task.disabled"
                v-if="!task.isChecked"
              />
              <div
                v-else-if="task.isChecked"
                :class="{ disableClick: task.disabled }"
                class="checkbox-mark"
                @click="undoTask(task)"
              >
                X
              </div>
              <span
                v-html="task.name"
                style="vertical-align: top;  width: 90%;font-weight:bold; text-decoration: underline; cursor: pointer;"
                id="taskName"
                @click.prevent="uploadDocument(task)"
              ></span>
              <img
                src="https://i.ibb.co/cDGJBVS/Picture-1.png"
                alt="Picture-1"
                border="0"
                style="width:5%; margin-left:10%; cursor: pointer;"
                @click="openPopup(task)"
              />
            </td>
            <td
              v-for="(week, weekIndex) in weeks"
              :key="weekIndex"
              style="width: 150px;"
            >
              <div
                v-if="isTaskInWeek(task, weekIndex)"
                v-bind:class="{ 'ganttWhiteBackGround':isTaskInWeek(task, weekIndex) , 'ganttGreenBackGround':isTaskInWeek(task, weekIndex) && task.isChecked }"
                class="gantt-bar"
                :style="getBarStyle(task, weekIndex)"
              ></div>
            </td>
          </tr>
          <tr>
            <td style="width: 25%;">
              <p id="taskHeading">Installationsvoraussetzung:</p>
            </td>
          </tr>
          <tr
            v-for="(task, index) in Installationsvoraussetzung"
            :key="index"
            style="height: 50px;"
          >
            <td
              style="width: 250px; display: flex; align-items: center; margin-top: 1px;"
            >
              <input
                id="checkboxInput"
                type="checkbox"
                v-model="task.isChecked"
                @change="onCheckboxChange(task)"
                :disabled="task.disabled"
                v-if="!task.isChecked"
              />
              <div
                v-else-if="task.isChecked"
                :class="{ disableClick: task.disabled }"
                class="checkbox-mark"
                @click="undoTask(task)"
              >
                X
              </div>
              <span
                v-html="task.name"
                style="vertical-align: top;  width: 90%;font-weight:bold; text-decoration: underline; cursor: pointer;"
                id="taskName"
                @click.prevent="uploadDocument(task)"
              ></span>
              <img
                src="https://i.ibb.co/cDGJBVS/Picture-1.png"
                alt="Picture-1"
                border="0"
                style="width:5%; margin-left:10%; cursor: pointer !important;"
                @click="openPopup(task)"
              />
            </td>
            <td
              v-for="(week, weekIndex) in weeks"
              :key="weekIndex"
              style="width: 150px;"
            >
              <div
                v-if="isTaskInWeek(task, weekIndex)"
                v-bind:class="{ 'ganttWhiteBackGround':isTaskInWeek(task, weekIndex) , 'ganttGreenBackGround':isTaskInWeek(task, weekIndex) && task.isChecked }"
                class="gantt-bar"
                :style="getBarStyle(task, weekIndex)"
              ></div>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <button id="finishButton" @click.prevent="finished">Fertig? Abschicken!</button>
      </div>
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import preContractOwner from '../../server/models/preContractOwner';
export default {
  data() {
    return {
      isFinishedtask: false,
      isFinished:false,
      hasFile: false,
      taskNameforUpload: "",
      opentUploadpopup: false,
      selectedFile: null,
      isPopupOpen: false,
      isBauantragsformularActive: false,
      weeks: [],
      BauantragErstellen: [],
      WHGFlache: [],
      Abwassermanagement: [],
      Installationsvoraussetzung: [],
      barWidth: 50 ,// Set your desired bar width in pixels
      startWeek: '',
      duration:'',
      ownerDetails:{},
      showUpdatesUpload: false,
      updatesUpload: '',
      infoTaskName:''
    };
  },
  computed: {
    currentWeekIndex() {
      const currentDate = new Date();
      const currentWeek = this.getWeek(currentDate);
      return this.weeks.findIndex(week => week === `KW ${currentWeek}`);
    }
  },
  methods: {
    addTracking(name, action){
      console.log("Track",name,action)
      axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/tracking/addTracking', {
        name: name,
        logedInUserID: this.ownerDetails._id,
        action: action,
        description: this.ownerDetails.name + ' '+ action + ' the '+ name
      }).then((response) => {
        console.log(response)
      }).catch((error) => {
        console.log(error)
      })
    },
    finished(){
      this.isFinished = true
    },
    deleteSelectedFile() {
      this.selectedFile = null;
      this.hasFile = false;
      this.addTracking('Removed Item while upload', 'deleted')
      // You can perform additional actions if needed, e.g., delete from server
    },
    handleFileUpload() {
      this.addTracking('File uploading', 'uploaded')
      this.selectedFile = null;
      this.hasFile = false;
      const fileInput = this.$refs.file;
      const files = fileInput.files;
        this.selectedFile = files[0] || null;
        this.hasFile = true;
        const formData = new FormData();
        formData.append('file', this.selectedFile,this.selectedFile.name);
        formData.append('taskName', this.taskNameforUpload);
        formData.append('owner', this.ownerDetails.name);
        axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/precontract/uploadFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          if(response.status == 200){
            this.showUpdatesUpload = true;
            this.updatesUpload = "Datei erfolgreich aktualisiert!"
            this.generateWeeksArray();
            setTimeout(() => {
            this.showUpdatesUpload = false;
            this.updatesUpload = "";
            this.closeUploadPopup();
          }, 3000);
          }
        }, (error) => {
          console.log(error)
          
          this.showUpdatesUpload = true;
            this.updatesUpload = "Entschuldigung! Irgendetwas ist schief gelaufen!"
            setTimeout(() => {
            this.showUpdatesUpload = false;
            this.updatesUpload = "";
            this.closeUploadPopup();
          }, 3000);
          });
    },
    uploadDocument(task) {
      console.log(task);
        window.open(task.pdfLink, '_blank')
    },
    openPopup(task) {
      this.addTracking(`${task.name} popup opened`, 'Clicked')
        this.isBauantragsformularActive = true;
        this.isPopupOpen = true;
    },
    closePopup() {
      this.addTracking('Popup closed', 'Closed')
      this.isBauantragsformularActive = false;
      this.isPopupOpen = false;
      this.isFinished = false
      this.isFinishedtask = false
    },
    closeUploadPopup() {
      this.addTracking('Upload popup closed', 'Closed')
      this.opentUploadpopup = false;
      this.selectedFile = null;
      this.hasFile = false;
    },
    async generateWeeksArray() {
    try {
      console.log(name);
     const response = await axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/precontract/gettaskDetails', { ownerName: localStorage.getItem('token') }); 
      //const response = await axios.post('http://localhost:3000/api/precontract/gettaskDetails', { ownerName: localStorage.getItem('token') });
      console.log(response.data);
      this.BauantragErstellen = response.data.BauantragErstellen;
      this.WHGFlache = response.data.WHGFlache;
      this.Abwassermanagement = response.data.Abwassermanagement;
      this.Installationsvoraussetzung = response.data.Installationsvoraussetzung;
      this.startWeek = parseInt(response.data.startingWeek);
      this.duration = parseInt(response.data.totalWeeksNeeded);

      let startNumber = parseInt(response.data.startingWeek);
      let increment = 1;
      let numberOfElements = parseInt(response.data.totalWeeksNeeded-1);

      let numbersArray = [];
      for (let i = 0; i < numberOfElements; i++) {
        numbersArray.push(startNumber + i * increment);
      }

      this.weeks = numbersArray.map(weekNumber => `KW ${weekNumber}`);
      this.weeks.push("GO LIVE");
      console.log(this.weeks);
    } catch (error) {
      console.log(error);
    }
  },
    getWeek(date) {
      date = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
      var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
      var weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
      return weekNo;
    },
    isTaskInWeek(task, weekIndex) {
      if (task.startWeek === "GO LIVE") {
        // Special case for "GO LIVE"
        return weekIndex === this.weeks.length - 1; // "GO LIVE" should be in the last week
      } else {
        const taskEndWeekIndex = this.weeks.findIndex(
          week => week === `KW ${task.startWeek + task.duration - 1}`
        );
        return (
          weekIndex >=
            this.weeks.findIndex(week => week === `KW ${task.startWeek}`) &&
          weekIndex <= taskEndWeekIndex
        );
      }
    },
    getBarStyle(task, weekIndex) {
      const color = task.isChecked ? "#97bf0d" : "white";

      return {
        marginLeft:
          weekIndex ===
          this.weeks.findIndex(week => week === `KW ${task.startWeek}`)
            ? 0
            : undefined,
        marginRight:
          weekIndex ===
          this.weeks.findIndex(
            week => week === `KW ${task.startWeek + task.duration - 1}`
          )
            ? 0
            : undefined,
        backgroundColor: color,
        borderRadius: "3px",
        height: "25px",
        width: "119%",
      };
    },
    undoTask(task) {
      console.log(task);
      task.isChecked = false;
      this.addTracking(task.name+' marked as false', "Click");
    },
    onCheckboxChange(task) {
      this.infoTaskName = task.name
      this.isFinishedtask = true
      this.addTracking(task.name+' checkbox true', "Click");
      console.log(`${task.name} checkbox status: ${task.isChecked}`);

    },
    showCallNotification(week) {
      // Show call notification every two weeks except the last week
      return (
        week !== "GO LIVE" &&
        week !== this.weeks[this.weeks.length - 1] &&
        week.substring(3) % 2 === 0
      );
    }
  },
 mounted() {
   this.generateWeeksArray();
   if(localStorage.getItem('token')) {
           axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/precontract/checkToken', {
              //axios.post('http://localhost:3000/api/precontract/checkToken', {
                token: localStorage.getItem('token')
            }).then((response) => {
                if(response.status === 200) {
                    this.ownerDetails = response.data
                    console.log(this.ownerDetails)
                }
                else {
                    this.$router.push('/')
                }
            })
            .catch((error) => {
                this.$router.push('/')
            })
        }
        else {
            this.$router.push('/')
        }
  console.log(this.weeks);
  }
};
</script>

<style>
#info{
  padding:5%
}
#callNotification{
  position: absolute;
   top: -40%;
    left: 60%; 
    color: red; 
    width: 70%;
}
@media (min-width: 1025px){
  #callNotification{
    position: absolute;
   top: -110%;
    left: 50%; 
    color: red; 
    width: 70%;
  }
  #taskHeading {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-left: 221%;
}
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #97bf0d; /* Change the color to your preference */
  animation: spin 1s infinite linear;
  margin-right: 10px;
}

#uploading {
  margin: 0;
}
.uploadButton {
  background-color: #97bf0d;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 1%;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 7%;

}

.file-label {
  background-color: #97bf0d;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 1%;
}

.file-label input {
  display: none;
}

.selected-file {
  text-align: center;
  margin-top: 2%;
  font-weight: bold;
}
.delete-icon {
  cursor: pointer;
  font-size: 15px;
  margin-right: 5px;
  padding: 1px;
  border: 1px solid grey;
  border-radius: 20%;
}
.delete-icon:hover {
  font-size: 17px;
  transform: scale(1.1);
  transition: 0.5s;
}
.upload-icon {
  cursor: pointer;
  font-size: 15px;
  margin-left: 5px;
  border: 1px solid grey;
  border-radius: 20%;
}
.upload-icon:hover {
  background-color: #97bf0d;
}

.close {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.popup button.close:hover {
  background-color: #cc4d35; /* Darker shade on hover */
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.center {
  position: fixed;
}

.popup {
  background: #f5f5f5;
  width: auto;
  height: auto;
  border-radius: 10px;
  border: 2px solid #97bf0d;
  box-sizing: border-box;
  text-align: center;
  opacity: 0;
  z-index: 0;
  color: black;
}
.active {
  opacity: 1;
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 100ms cubic-bezier(1.01, 0.89, 0.43, 0.19);
}
.uploadPopup {
  opacity: 1;
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 35%;
  height: auto;
  transform: translate(-50%, -50%);
  transition: transform 100ms cubic-bezier(1.01, 0.89, 0.43, 0.19);
}

.gantt-table {
  border-collapse: collapse;
  width: 80%;
  margin: 3% auto;
  overflow-x: auto;
}

th,
td,
tr {
  text-align: left;
}

/* .ganttWhiteBackGround{
  border-right: 1px solid white;
}
.ganttGreenBackGround{
  
  border-right: 1px solid #97bf0d;
} */
.gantt-bar {
  width: 90%;
  height: 10%;
  background-color: white;
  border-radius: 5px;
  margin-top: 2%;
  transition: background-color 0.5s ease;
}
#taskHeading {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin-left: 7%;
}
#taskHeading::first-letter{
  font-size: 35px;
  color: #97bf0d;
}
#checkboxInput {
  margin-right: 5%;
  background-color: transparent;
  font-weight: bolder;
  border: 1px solid white;
  color: white;
  font-size: 15px;
  margin-left: 1%;
  padding: 0;
  height: 20px;
  width: 9%;
  text-emphasis: none;
  text-align: center;
  cursor: pointer;
}
.borderGo {
  border: 1px solid white;
  text-align: center;
  font-size: small;
}
.current-week {
  position: relative;
}

.current-week::after {
  content: "";
  position: absolute;
  top: 80%;
  bottom: 20%;
  border: 5px solid #97bf0d; /* Change the color as needed */
  z-index: 1; /* Ensure it's above other content */
  width: 40%;
}
#finishButton {
  margin-top: 2%;
  margin-left: 2%;
  width: 15%;
  height: 40px;
  background-color: white;
  border-radius: 5px;
  border: none;
  color: #97bf0d;
  font-weight: bold;
  font-size: 15px;
}
#taskName:hover {
  cursor: pointer;
  color: #97bf0d;
}
#finishButton:hover {
  cursor: pointer;
  background-color: #97bf0d;
  color: white;
}
.checkbox-mark {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
  background-color: #97bf0d;
  font-weight: bold;
  border: 1px solid #97bf0d;
  color: white;
  font-size: 18px;
  margin-left: 1%;
  padding: 0;
  width: 9%;
  height: 20px;
  text-align: center;
  border-radius: 2px;
}

.disableClick {
  pointer-events: none;
  cursor: default;
}
.close {
  position: absolute;
  top: 0;
  right: 14px;
  font-size: 20px;
  margin-left: 50%;
  margin-top: 2%;
  color: white;
  font-weight: bold;
  transition: 0.3s;
}
@media (max-width: 768px) {
  .gantt-table {
    width: 100%;
    font-size: smaller;
  }
  .gantt-bar {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  #taskHeading {
    margin-left: 0;
  }
  #finishButton {
    width: 30%;
  }
  .checkbox-mark {
    width: 5%;
    margin-left: 4%;
  }
  #checkboxInput {
    width: 10%;
  }
  .close {
    margin-left: 80%;
    font-size: medium;
  }
  .popup h3 {
    font-size: medium;
    margin-top: 7%;
    padding: 5%;
  }
  .popup p {
    font-size: small;
    padding: 5%;
  }
  #week {
    font-size: small;
    width: 110%;
    margin-left: -5%;
  }
}
@media (max-width: 1524px) {
  .gantt-table {
    width: 80%;
    font-size: medium;
  }
  #callNotification{
    position: absolute;
   top: -35%;
    left: 50%; 
    color: red; 
    width: 70%;
  }
  #taskHeading {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-left: 10%;
}
  .gantt-bar {
    width: 80%;
    margin: 0;
    padding: 0;
  }

  #finishButton {
    width: 30%;
  }
  .checkbox-mark {
    width: 9%;
    
  }
  #checkboxInput {
    width: 10%;
  }
  .close {
    margin-left: 80%;
    font-size: medium;
  }
  .popup h3 {
    font-size: medium;
    margin-top: 7%;
    padding: 2%;
  }
  .popup p {
    font-size: small;
    padding: 2%;
  }
  #week {
    font-size: medium;
    width: 100%;
    margin: 5%;
  }
}
</style>
