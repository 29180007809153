import { createRouter, createWebHashHistory } from "vue-router";
import LoginPage from '@/components/LoginPage'
import ContractPage from '@/components/Contractpage'
const routes = [
 {
      path: '/',
      name: 'LoginPage',
      component: LoginPage
    },
    {
      path: '/contract',
      name: 'ContractPage',
      component: ContractPage
    }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;