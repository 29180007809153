<template>
    <div id="footer">
        <div>
            <p @click="openDaten">DATENSCHUTZERKLÄRUNG</p>
        </div>
        <div>
            <p @click="openOurWebsite">www.katma-clean.de</p>
        </div>
        <div>
            <p @click="openImpressumInNewTab">IMPRESSUM</p>
        </div>
    </div>

</template>
<script>
export default {
    name: 'Footer',
  methods: {
    openImpressumInNewTab() {
      const impressumUrl = 'https://www.katma-clean.de/impressum/';
      window.open(impressumUrl, '_blank');
    },
    openDaten() {
      const datenschutzUrl = 'https://www.katma-clean.de/datenschutz/';
      window.open(datenschutzUrl, '_blank');
    },
    openOurWebsite(){
        const websiteUrl = 'https://www.katma-clean.de/';
        window.open(websiteUrl, '_blank')
    }
  },
};
</script>
<style scoped>
p {
    color: white;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
#footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 10vh;
    background-color: black;
    color: white;
    font-family: 'Oswald', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}
@media(max-width: 767px) {
    p{
        font-size: 0.8em;
    }
}

</style>
