<template>
    <div id="contracContainer">
        <div v-if="loading" class="loading-container" :class="{whiteBackgroung:whiteTrue}">
          <img :src="ownerDetails.logoImageURL"  border="0" style="display:none;">
    <div class="loading-content" v-if="!mobile">
    <img src="https://container-hygiene.de/webapps/precontract/Logo_KATMA_wei%C3%9F%20(1).png" alt="Logo-KATMA-wei" border="0" id="logoImageforContract">
    <div v-if="whiteTrue3" class="saving-overlay">
      <div class="saving-animation">
        <div class="loading-dot2"></div>
        <div class="loading-dot2"></div>
        <div class="loading-dot2"></div>
      </div>
    </div>
    </div>
    <div class="loading-content" v-if="mobile">
    <img src="https://container-hygiene.de/webapps/precontract/Logo_KATMA_wei%C3%9F%20(1).png" alt="Logo-KATMA-wei" border="0" id="logoImageforContract"   class="fade-in">
    <div v-if="whiteTrue3" class="saving-overlay">
      <div class="saving-animation">
        <div class="loading-dot2"></div>
        <div class="loading-dot2"></div>
        <div class="loading-dot2"></div>
      </div>
    </div>
    </div>
  </div>
    <div id="contractPage" v-else>
  <div id="contractParties">
    
    <img src="https://container-hygiene.de/webapps/precontract/Logo_KATMA_wei%C3%9F%20(1).png" alt="Logo-KATMA-wei" border="0" id="logoImageforContract"   class="fade-in" @mouseover="addTracking('Katma Logo','mouseover')">
    <!-- <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ0L8L8njUWshrMz0DlBEEn1-CoRmguVDJjQ&s" alt="X-wordpress-1-qf9gtss34rqsgum6330xjlbcjezaqdx4x0klrownec" border="0"  class="fade-in"> -->

    <h1  id="xLogo" style=" font-size: 4em; font-weight:100 !important;font-family: ">X</h1>
    <img :src="ownerDetails.logoImageURL" alt="The-Company" border="0" id="logoImageforContractsecondparty" class="fade-in" @mouseover="addTracking('Company Logo','mouseover')">
  </div>
  <br/>
  <div id="horizontalLine"></div>
  <div id="contractTags" @mouseover="addTracking('Welcome Line','mouseover')">
    <h3 id="welcomeTag">HERZLICH WILLKOMMEN</h3>
    <p id="contractTag">Zur Angebotserstellung für die KATMA CleanControl finden Sie auf dieser Seite dafür alle notwendigen Schritte, Dokumente und Richtlinien.</p>
  </div>
  <div id="contractPapaers" @mouseover="addTracking('Contract PDF','mouseover')">
    <div>
      <PdfComponent/>
    </div>
  </div>
  <div>
    <Gantt v-if="ownerDetails.isSigned"/>
  </div>
  <hr style="width:60%; margin:auto;"/>
  <div @mouseover="addTracking('Sales details','mouseover')">
    <div id="contact">       
                <div>
                  <img
                id="employeerheight"
                src="https://container-hygiene.de/webapps/precontract/KeyAccount%20Felix_Contact.jpeg"
                alt="Ellipse-2"
                border="0"
                class="imgmain"
              />

                </div>
                <div>
                <h4>Felix Kathöfer</h4>
                <div class="information">
                  <strong class="textnew" style="display: inline">FOUNDER & KEY ACCOUNT MANAGER</strong>
                  <div>
                    
                    <p class="phone" style="display: inline">+49 1627543842</p>
                  </div>
                  <div>
                 
                    <a
                      class="phone"
                      style="display: inline; color: white; text-decoration: none;"
                      href="mailto:felix.kathoefer@katma-clean.de"
                      >felix.kathoefer@katma-clean.de</a
                    >
                  </div>
                </div> 
                </div> 
                </div>
    </div>
  <div id="horizontalLine"></div>
<Footer/>
<Profile/>

    </div>
</div>
</template>
<script>
import axios from 'axios'
import PdfComponent from './PdfComponent.vue';
import Footer from './Footer.vue';
import Profile from './Profile.vue';
import Gantt from './Gantt.vue';
export default {
  name: 'Contractpage',
  data() {
    return {
      isPopupOpen: false,
      ownerDetails:{},
      loading: true,
      whiteTrue: false,
      whiteTrue2: false,
      targetDuration: 4.999999,
      targetDuration2: 3.9,
      whiteTrue3: true,
      mobile:false
    
    }
  },
  components: {
    PdfComponent,
    Footer,
    Profile,
    Gantt
  },
  methods:{
    addTracking(name, action){
      console.log(name,this.ownerDetails)
      axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/tracking/addTracking', {
        name: name,
        logedInUserID: this.ownerDetails._id,
        action: action,
        description: this.ownerDetails.name + ' '+ action + ' the '+ name
      }).then((response) => {
        console.log(response)
      }).catch((error) => {
        console.log(error)
      })
    },
      checkVideoDuration() {
      const videoElement = this.$refs.videoPlayer;
      if (videoElement && videoElement.currentTime >= this.targetDuration2 && videoElement.currentTime<=6) {
        // Call your function when the video reaches the target duration
        this.whiteTrue2 = false
        
        this.onVideoTargetDurationReached();
      }
      if (videoElement && videoElement.currentTime >= this.targetDuration && videoElement.currentTime<=6) {
        // Call your function when the video reaches the target duration
        this.whiteTrue = false
        this.whiteTrue3 = true
        
        this.onVideoTargetDurationReached();
      }
      
    },
    onVideoEnded() {
      // Your custom function logic here
      //console.log('Video ended.');
      this.loading = false
      this.whiteTrue3 = false
    },
    onVideoTargetDurationReached() {
      // Your custom function logic here
      //console.log('Video reached target duration of 5 seconds.');
    },
  },
  mounted(){
    //this.$refs.videoPlayer.play();
    if(localStorage.getItem('token')) {
    axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/precontract/checkToken', {
      //axios.post('http://localhost:3000/api/precontract/checkToken', {
        ///api/precontract/checkToken', {
        token: localStorage.getItem('token')
      }).then((response) => {
        if(response.status === 200) {
         this.ownerDetails = response.data
         console.log(this.ownerDetails)
        }
        else {
          this.$router.push('/')
        }
      })
      .catch((error) => {
        this.$router.push('/')
      })
    }
    else {
      this.$router.push('/')
    }
    setTimeout(() => {
      this.loading = false
    }, 4000),
    setTimeout(() => {
      
    }, 5500)
    if(window.innerWidth<800){
      this.mobile = true
    }
  }
}
</script>
<style>
#contracContainer{
  background-color: black;
}
#contact{
  display: inline-block;
 margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
}
.imgmain {
  max-width: 15%; 
  aspect-ratio: 3/2;
  margin-bottom:10px;
  border: 1px solid white;
}

.whiteBackgroung{
  background-color: white;
  width: 100%;
  height: 100%;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.loading-content {
  text-align: center;
}

#loadingImage {
  /* Add styles for the loading image if needed */
  max-width: 7%;
  max-height: 7%;

}
body {
  margin: 0;
  background-color: black;
}
.fade-in {
  animation: slideFromBottom 1.5s ease-out;
}
@keyframes slideFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
#contractPage {
    font-family: 'Oswald' !important ;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
    background-color: black;
    height: auto;
    width: 100%;
}
#logoImageforContract {
    width: 25%;
    margin: 8% 5%;

}
#logoImageforContractsecondparty {

    width: 25%;
    margin: 7% 5%;
}
#contractParties {
    margin-bottom: 15%;
    margin-top:15%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
#contractBetween{
    font-size: 7em;
    font-weight:100;
    color: white;
}
#horizontalLine{
    border: 1px solid white;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8%;
}
#contractTags{
    margin-top: 2%;
    text-align: center;
    color: white;
}
#welcomeTag{
    font-size: 3em;
    font-weight: bold;
}
#contractTag{

    margin: 0 auto;
    margin-top: 2%;
    width: 75%;
}
@media(max-width: 767px){
    #xLogo{
       width: 4%;
       font: 1.5em sans-serif !important;
      
    }
    #contractPage {
        font-family: 'Osward' !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: white;
        background-color: black;
        height: auto;
        width: 100%;
        margin: 0;
    }
    .imgmain {
  max-width: 150px; 
  height: 80px;
  margin-bottom:10px;
}
    #logoImageforContract {
        width: 45%;
        aspect-ratio: 30.8;
        height: 8vh;
        margin: 3% 5%;
    }
    #logoImageforContractsecondparty {
        width: 35%;
        height: 8vh;
        margin: 5% 5%;
    }
    #contractParties {
        margin-bottom: 68%;
        margin-top:68%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #contractBetween{
        font-size: 7em;
        font-weight:100;
        color: white;
    }
    #horizontalLine{
        border: 1px solid white;
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 8%;
    }
    #contractTags{
        margin-top: 2%;
        text-align: center;
        color: white;
    }
    #welcomeTag{
        font-size: 1.4em;
        font-weight: bold;
    }
    #contractTag{
        font-size: .6em;
        font-weight: bold;
        margin: 0 auto;
        margin-top: 2%;
        width: 80%;
    }

}
.saving-overlay {
  margin-top: 80px;
  position: fixed;
  top: 10%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's on top of other elements */
}

.saving-animation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-dot {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  background-color: #97bf0d; /* You can customize the color */
  border-radius: 50%;
  animation: bounce 0.5s ease-in-out infinite alternate;
}
.loading-dot2 {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  background-color: white; /* You can customize the color */
  border-radius: 50%;
  animation: bounce 0.5s ease-in-out infinite alternate;
}

@keyframes bounce {
  to {
    transform: translateY(-8px);
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    #contractPage {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: white;
        background-color: black;
        height: auto;
        width: 100%;
        margin: 0;
    }
    #logoImageforContract {
        width: 35%;
        aspect-ratio: 30.8;
        height: 8vh;
        margin: 5% 5%;
    }
    #logoImageforContractsecondparty {
        width: 35%;
        height: 8vh;
        margin: 5% 5%;
    }
    #contractParties {
        margin-bottom: 48%;
        margin-top:50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #contractBetween{
        font-size: 7em;
        font-weight:100;
        color: white;
    }
    #horizontalLine{
        border: 1px solid white;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 8%;
    }
    #contractTags{
        margin-top: 2%;
        text-align: center;
        color: white;
    }
    #welcomeTag{
        font-size: 1.4em;
        font-weight: bold;
    }
    #contractTag{
        font-size: .8em;
        font-weight: bold;
        margin: 0 auto;
        margin-top: 2%;
        width: 65%;
    }
}
</style>