<template>
<div id="loginpage">
    <img src="https://i.ibb.co/G7Q84BB/Logo-KATMA-wei.png" alt="Logo-KATMA-wei" border="0" id="logoImage">
    <form @submit.prevent="login">
        <div>
        <input type="text" name="email" id="email" placeholder="UNTERNEHMEN" v-model="ownerName"/>
        </div>
        <div>
        <input type="password" name="password" id="password"  placeholder="PASSWORT" v-model="password"/>
        </div>
        <br/>
        <button type="submit" id="loginButton">EINLOGGEN</button>
        <br/>
        <br/>
        <p v-if="shoingUpdates" style="color: red; font-size: larger; font-weight: bolder;">Falsche Anmeldedaten!</p>
    </form>
</div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'LoginPage',
    data() {
        return {
            ownerName: '',
            password: '',
            updates: '',
            shoingUpdates: false
        }
    },
    methods: {
        login() {
            axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/precontract/login', {
               //axios.post('http://localhost:3000/api/precontract/login', {
                ownerName: this.ownerName,
                password: this.password
            })
            .then((response) => {
               if(response.status === 200) {
                     localStorage.setItem('token', response.data)
                     axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/tracking/loginTracking', {
                        sessioToken: response.data})
                   this.$router.push('/contract')
               }
               else {
                this.shoingUpdates = true
                   setTimeout(() => {
                    this.shoingUpdates = false
                   }, 5000)
               }
            })
            .catch((error) => {
                this.shoingUpdates = true
                   setTimeout(() => {
                    this.shoingUpdates = false
                   }, 5000)
            })
            
        }
    },
    mounted(){
        if(localStorage.getItem('token')) {
             axios.post('https://precontract-katma-2b05960cc36b.herokuapp.com/api/precontract/checkToken', {
               // axios.post('http://localhost:3000/api/precontract/checkToken', {
                token: localStorage.getItem('token')
            }).then((response) => {
                if(response.status === 200) {
                    this.$router.push('/contract')
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }
}

</script>
<style scoped>
* {
    margin: 0;
    padding: 0;
}
body{
    margin: 0;
    padding: 0;
}
#loginpage {
    font-family: 'Oswald' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
    background-color: black;
    height: 100vh;
    width: 100vw;
    padding: 0;
}
#logoImage {
    margin-top: 10%;
    width: 18%;
}
form {
    margin-top: 4%;
}
input {
    width: 30%;
    height: 6vh;
    border-radius: 5px;
    border: 1px solid #2c3e50;
    background-color: white;
    padding-left: 5px;
    font-size: 1.5em;
    margin-top: 1%;
}
#loginButton{
    width: 6%;
    height: 5vh;
    background-color: white;
    border: none;
    color: black;
    font-size: .8em;
    margin-top: 2%;
}
#loginButton:hover{
    color:#97bf0d;
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.5s;
    
}
input::placeholder{
      /* Center the placeholder text */
  text-align: center;

/* Change the color of the placeholder text */
color: black;
font-size: medium;
/* Replace with your desired color code */

}
@media(max-width: 767px){
    #loginpage {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: black;
    height: 100vh;
    width: 100vw;
    margin-top: 40%;
    padding: 0;
}
    #logoImage {
        margin-top: 10%;
        width: 40%;
    }
    input {
        width: 60%;
        height: 6vh;
        border-radius: 5px;
        border: 1px solid #2c3e50;
        padding-left: 5px;
        font-size: 1em;
        margin-top: 1%;
        text-align: center;
    }
    #loginButton{
        width: 30%;
        height: 5vh;
        border: none;
        background-color: white;
        color: black;
        font-size: .8em;
        margin-top: 2%;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    #loginpage {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: black;
    height: 100vh;
    width: 100vw;
    margin-top: 30%;
    padding: 0;
}
    #logoImage {
        margin-top: 10%;
        width: 30%;
    }
    input {
        width: 50%;
        height: 6vh;
        border-radius: 5px;
        border: 1px solid #2c3e50;
        padding-left: 5px;
        font-size: 1.5em;
        margin-top: 1%;
        text-align: center;
    }
    #loginButton{
        width: 15%;
        height: 5vh;
        border: none;
        background-color: white;
        color: black;
        font-size: .8em;
        margin-top: 2%;
    }
}
@media (min-width: 1025px){
    #logoImage {
        margin-top: 10%;
        width: 15%;
    }
    input {
        width: 20%;
        height: 6vh;
        border-radius: 5px;
        border: 1px solid #2c3e50;
        padding-left: 5px;
        font-size: 1.5em;
        margin-top: 1%;
        text-align: center;
    }
    #loginButton{
        width: 10%;
        height: 5vh;
        border:0;
        background-color: white;
        color: black;
        font-size: .8em;
        margin-top: 2%;
    }
}
</style>