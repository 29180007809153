<template>
    <div @mouseleave="profileHide" id="black">
        <img src="../assets/images.jpeg" alt="logo"  @mouseover="profileShown" id="profileButton">
        <div v-if="profileListShow" id="profileItems"  @mouseover="profileShown">
            <div>
                <img src="../assets/images.jpeg" alt="logo" id="profileImage">
            </div>
            <div>
            <ul>
                <li @click="logout">Abmelden</li>
            </ul>
            </div> 

        </div>
    </div>
</template>
<script>
export default{
    name: 'Profile',
    data(){
        return{
            profileListShow: false,
        }
    },
    methods:{
        profileShown(){
            this.profileListShow = true;
        },
        profileHide(){
            this.profileListShow = false;
        },
        logout(){
            localStorage.clear()
            this.$router.push('/');
        }
    }
}

</script>
<style scoped>
#black{
    background-color: black !important;
    z-index: 0;
}
ul{
    list-style-type: none;
    margin: 2%;
    padding: 2%;
}
li{
    margin: 2%;
    padding: 2%;
    cursor: pointer;
}
li:hover{
    color: lightblue;
}
#profileItems{
  
    background-color: grey;
    position: absolute;
    top: 4%;
    right: 0;
    width: 15%;
    height: auto;
    border: 1px solid black;
    border-radius: 5px;
    z-index: 1;
}
#profileButton{
    width: 3%;
    height: 5%;
    position: absolute;
    top: 0;
    right: 2%;
    cursor: pointer;
}
#profileImage{
    margin-top: 1%;
    width: 30%;
    height: 50%;
}
img:hover{
    opacity: 0.5;
}
@media(max-width: 768px){
    #profileItems{
        width: 30%;
    }
    #profileButton{
        background-color: black;
        width: 8%;
        height: 5%;
    }
    #profileImage{
        width: 50%;
        height: 50%;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px){
    #profileItems{
        width: 20%;
    }
    #profileButton{
        width: 5%;
        height: 4%;
    }
    #profileImage{
        width: 50%;
        height: 50%;
    }
}

</style>